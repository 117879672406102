{
  "name": "neo-gallery",
  "version": "1.1.2",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.7",
    "@angular/cdk": "^18.2.8",
    "@angular/cli": "^18.2.8",
    "@angular/common": "^18.2.7",
    "@angular/compiler": "^18.2.7",
    "@angular/compiler-cli": "^18.2.7",
    "@angular/core": "^18.2.7",
    "@angular/forms": "^18.2.7",
    "@angular/material": "^18.2.8",
    "@angular/platform-browser": "^18.2.7",
    "@angular/platform-browser-dynamic": "^18.2.7",
    "@angular/router": "^18.2.7",
    "rxjs": "~7.8.0",
    "uuid": "^10.0.0",
    "zone.js": "^0.14.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.8",
    "@types/jasmine": "~5.1.4",
    "@types/uuid": "^9.0.2",
    "typescript": "5.4"
  }
}
